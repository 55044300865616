import {
    Alert,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    Link, TextField,
    Typography
} from "@mui/material";
import React, {useEffect} from "react";
import PocketBase from 'pocketbase';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit">
                Pratt Software Inc
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


export const pb = new PocketBase('https://api.snapdiet.app');

function App() {
    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [msg, setmSG] = React.useState('');
    const [error, setError] = React.useState(null);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const send = async () => {
        try {
            await pb.collection('contactsPrattSW').create({text: msg, email});
            setEmail('')
            setmSG('')
            handleClose()
        } catch (e) {
            setError('Not valid Email')
            console.log(e)
        }
    }

     useEffect(() => {
         if (window.location.pathname.includes('support')) {
             handleClickOpen()
         }
     }, []);
    return (
    <div className="App">
        <Container maxWidth="lg" style={{    display: "flex",
            alignItems: 'center',
            justifyContent:' space-between',
            width: 1000,
            gap: 100,
        height: '80vh'}}>
            <div>
                <div><Typography variant="h2">Pratt Software Inc</Typography></div>
                <div><Typography variant="h6"> - web and mobile application development firm</Typography></div>

            </div>

            <div style={{
                gap: 15, display: 'flex',
                flexDirection: 'column'}}>
                <Card sx={{ width: 350 }}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            StockSpace
                        </Typography>
                        <Typography variant="body2">
                            Web application for young generation of investors. StockSpace makes finding best-dividends and trading ideas easier and smoother.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" onClick={() => window.open('https://stockspace.app/')}>Learn More</Button>
                    </CardActions>
                </Card>
                <Card sx={{ width: 350 }}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            SnapDiet                        </Typography>
                        <Typography variant="body2">
                            Healthy diet with personal certified nutritionist in your pocket
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small"  onClick={() => window.open('https://snapdiet.app/')}>Learn More</Button>
                    </CardActions>
                </Card>
            </div>
        </Container>



        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Contact us</DialogTitle>
            <DialogContent style={{minWidth: 300,paddingTop: 15}}>
                {error && <Alert style={{marginBottom: 15}} severity="error">{error}</Alert>}
                    <TextField fullWidth size="medium" id="outlined-basic" label="Email" type='email' variant="outlined"
                               onChange={(e) => setEmail(e.target.value)} />

                        <TextField   multiline={true}
                                     rows={3} fullWidth onChange={(e) => setmSG(e.target.value)} style={{marginTop: 10,}}  label="Message"  />
            </DialogContent>
            <DialogActions>
                <Button disabled={msg.length === 0 || email.length === 0} onClick={send}>Send</Button>
            </DialogActions>
        </Dialog>

        <Box component="footer" sx={{ bgcolor: 'background.paper', py: 6 }}>
            <Container maxWidth="lg" style={{
                display: 'flex',
                justifyContent: 'center',
                gap: 30,
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Button variant="outlined" onClick={handleClickOpen}>
                    Contact us
                </Button>
                <Copyright />
            </Container>
        </Box>
    </div>
  );
}

export default App;
